import { CurrencyTypes } from './wallet';

export const failure = 0.01;
export const offline = 0.03;

export const nextHalvingDates = ['04/08/2028', '04/01/2032', '03/25/2036', '03/20/2040'];
export const BLOCK_REWARD = 3.125; // BTC per block

export const BLOCKS_PER_MONTH = 4320; // Approximate number of blocks per month

// export const btcProductionPerMiner = 0.0181544;
export const difficultyIncrement = 0.1;

export const btcPriceIncrements = {
  Industry: [0.95, 0.6, 0.35, -0.8, 0.3],
  Optimistic: [1.83, 0.6, 0.35, -0.8, 0.3],
  Pessimistic: [0, 0, 0, 0, 0],
};

export const maxHoldPeriod = btcPriceIncrements.Industry.length * 12; // months

export enum ScenarioTypes {
  Industry = 'Industry',
  Optimistic = 'Optimistic',
  Pessimistic = 'Pessimistic',
}
export const scenarioKeys = {
  [CurrencyTypes.EUR]: 'eUR',
  [CurrencyTypes.ETH]: 'eTH',
  [CurrencyTypes.BTC]: 'bTC',
  [CurrencyTypes.BNB]: 'bNB',
  [CurrencyTypes.USDC]: 'uSDC',
  [CurrencyTypes.USDT]: 'uSDT',
};
