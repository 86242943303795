import React, {
  ChangeEvent, useContext, useEffect, useRef, useState,
} from 'react';
import {
  NavLink, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';

import { AppElement, AppFC } from '../../../interfaces';
import {
  BTCInfoCard, CurrencyAmountBox, InfoCard, MixedChart,
} from '../../Molecules';

import {
  InfoIcon, MinusIcon, PantheonIcon, PlusIcon,
} from '../../Atoms/icons';
import { Button, Input, Loader } from '../../Atoms';
import { CryptoHandler, ProjectHandler } from '../../../stores/handlers';
import { ProjectInterface } from '../../../types/projects';
import {
  AuthPaths, ButtonCategory, CurrencyTypes, Paths, ProjectCurrencyTypes,
} from '../../../constants';
import { AuthToken } from '../../../services';
import {
  // formatNumber,
  getCurrencySign,
  getProjectCurrencyOptions,
  getProjectMinersOptions,
  getProjectOptions,
  numberWithCommas,
} from '../../../utils';
import {
  btcHalvingMonths, calculateCompoundPercentValue, calculateIRR, calculateMonthlyBTCProduction, calculateTotalBtcProductionForPeriod, getBuyingBtcAmount, getCumulativeRevenue, getIncomesYearly,
} from '../../../utils/calculator';
import {
  BLOCK_REWARD, BLOCKS_PER_MONTH, btcPriceIncrements, difficultyIncrement, failure, maxHoldPeriod, offline, ScenarioTypes,
} from '../../../constants/calculator';
import { ProjectsContext, ViewContext, ViewModalActionTypes } from '../../../stores';
import { InfoRawProps } from '../../Atoms/InfoRaw/types';
import { Select } from '../../Atoms';

export const Calculator: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectInterface[]>([]);
  const [project, setProject] = useState<ProjectInterface>();
  const [holdPeriod, setHoldPeriod] = useState<number>(maxHoldPeriod);
  const [scenario, setScenario] = useState<ScenarioTypes>(ScenarioTypes.Industry);
  const [btcPrice, setBTCPrice] = useState<string>();
  const [btcNetworkHashRate, setBtcNetworkHashRate] = useState<number>(0);
  const [irr, setIrr] = useState<number | null>(0);
  const [irrError, setIrrError] = useState<string | null>();
  const [currency, setCurrency] = useState<ProjectCurrencyTypes>(project?.currency || ProjectCurrencyTypes.USD);

  const [totalMiningPopup, setTotalMiningPopup] = useState(false);
  const [btcPricePopup, setBtcPricePopup] = useState(false);

  let totalBuyingBtcAmount = 0;
  let totalMiningBtcAmount = 0;
  let totalBuyingBtcAmountPrice = 0;
  let totalMiningBtcAmountPrice = 0;

  const { projectsState: { rates } } = useContext(ProjectsContext);

  const authToken: AuthToken = new AuthToken();
  const isAuth = !!authToken.getAccessToken();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith('/')) {
      const newPath = location.pathname.slice(0, -1);
      navigate(newPath);
    }
  }, [location, location.pathname]);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const {
    viewModal: {
      // isOpen,
      info,
    }, updateViewModal,
  } = useContext(ViewContext);

  const [searchParams] = useSearchParams();
  const orderQuantity = searchParams.get('miners');
  const projectId = searchParams.get('projectId');
  const scenarios = ['Industry', 'Optimistic', 'Pessimistic'];

  const { getProjects, getBtcNetworkHashRate, getRates } = ProjectHandler();
  const { getBitcoinPrice } = CryptoHandler();

  const {
    id,
    // currency,
    title,
    description,
    energySource,
    country,
    electricityPrice = 0,
    pricePerMiner = 0,
    minimumOrderQuantity = 0,
    soldSpots = 0,
    totalSpots = 0,
    wattage = 0,
    minerModel,
    hashRate = 0,
  } = project || {};

  // console.log('project', project);
  const [spotsAmount, setSpotsAmount] = useState(Number(orderQuantity) || minimumOrderQuantity);

  const currentBtcPrice = Number(btcPrice?.replace(',', ''));
  const halvingMonths = btcHalvingMonths();

  const availableSpotsAmount = totalSpots - soldSpots;
  const currentCurrency = getCurrencySign(currency);
  const yearsCount = Math.trunc(holdPeriod / 12);
  const initialInvestment = spotsAmount * pricePerMiner * (currency === ProjectCurrencyTypes.EUR ? Number(rates?.rates.eUR) : 1);
  const electricityUseCostYearly = (spotsAmount * electricityPrice * wattage * 0.001 * 24 * 365 * 0.96) * (currency === ProjectCurrencyTypes.EUR ? Number(rates?.rates.eUR) : 1);
  const electricityUseCostMonthly = electricityUseCostYearly / 12;

  const btcProductionPerMiner = calculateMonthlyBTCProduction(hashRate, btcNetworkHashRate, BLOCK_REWARD, BLOCKS_PER_MONTH);
  // console.log(`Monthly BTC Production: ${btcProductionPerMiner} BTC`);
  // console.log('irr', calculateIRR([-1708416, 2092299.32, 3582257.29, 4646757.62, 1602301.65, 919700.25]) * 100); // 154.11% (OK)
  const incomes = getIncomesYearly(holdPeriod, scenario, currentBtcPrice, initialInvestment, electricityUseCostYearly, spotsAmount, btcProductionPerMiner);
  const totalBtcProduction = calculateTotalBtcProductionForPeriod(holdPeriod - 1, spotsAmount, btcProductionPerMiner);// holdPeriod-1
  const totalRevenue = getCumulativeRevenue(holdPeriod - 1, spotsAmount, scenario, currentBtcPrice, btcProductionPerMiner);
  const equityMultiple = totalRevenue / initialInvestment;
  const calculateRevenueMonthly = (period: number) => {
    if (period === 0) return 0;
    const btcProductionPerMonth = calculateCompoundPercentValue(btcProductionPerMiner, period - 1, { yearlyPercentage: -difficultyIncrement, halvingMonths }) * spotsAmount;

    const btcPriceInMonth = calculateCompoundPercentValue(currentBtcPrice, period - 1, { scenario });

    const btcProductionCostPerMonth = btcProductionPerMonth * btcPriceInMonth;

    return btcProductionCostPerMonth * (1 - failure - offline);
  };
  const calculateCashFlowMonthly = (period: number) => (period > 0 ? calculateRevenueMonthly(period) - electricityUseCostMonthly : 0);
  const calculateCumulativeCashFlowMonthly = (period: number) => (getCumulativeRevenue(period - 1, spotsAmount, scenario, currentBtcPrice, btcProductionPerMiner) - period * electricityUseCostMonthly - initialInvestment);

  const handleCalculateIRR = () => {
    try {
      const result = calculateIRR(incomes);
      setIrr(result * 100);
      setIrrError(null);
    } catch (err) {
      setIrrError((err as Error).message);
      setIrr(null);
    }
  };

  // const cumulativeCashFlow = calculateCumulativeCashFlowMonthly(holdPeriod);
  // const getCashFlowColorClass = ()=>{
  //   switch (cumulativeCashFlow){
  //     case0
  //   }
  // } 'text-green'
  const revenuesPerMonth = project ? Array.from({ length: holdPeriod }, (_, i) => calculateRevenueMonthly(i + 1)) : [];
  const electricityUseCostPerMonth = project ? Array.from({ length: holdPeriod }, () => electricityUseCostMonthly) : [];
  const cashFlowsPerMonth = project ? Array.from({ length: holdPeriod }, (_, i) => calculateCashFlowMonthly(i + 1)) : [];
  const totalCashFlowsPerMonth = project ? Array.from({ length: holdPeriod }, (_, i) => calculateCumulativeCashFlowMonthly(i + 1)) : [];
  const labels = project ? Array.from({ length: holdPeriod }, (_, i) => i + 1) : [];

  useEffect(() => {
    Promise.all([getProjects(), getBtcNetworkHashRate(), getRates()]).then((response) => {
      if (response[0]) {
        setProjects((response[0] as ProjectInterface[]).filter((item) => item.soldSpots !== item.totalSpots));
      }
      if (response[1]) {
        setBtcNetworkHashRate(response[1]);
      }
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
    // Scroll to the top of the page after a redirect
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (incomes.length > 1) {
      handleCalculateIRR();
    }
  }, [incomes]);

  useEffect(() => {
    if (projectId && projects.length) {
      const currentProject = projects.find((item) => item.id.toString() === projectId);
      if (currentProject) {
        setProject(currentProject);
        if (currentProject.minimumOrderQuantity > spotsAmount) {
          setSpotsAmount(currentProject.minimumOrderQuantity);
        }
      }
    } else if (projects.length) {
      setProject(projects[0]);
      setSpotsAmount(projects[0].minimumOrderQuantity);
    }
  }, [projectId, projects]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (spotsAmount.toString() !== orderQuantity) {
      params.delete('miners');
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [spotsAmount]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (project && projectId && (project.id.toString() !== projectId)) {
      params.delete('projectId');
      navigate(`${location.pathname}?${params.toString()}`);
    }
  }, [project]);

  useEffect(() => {
    setIsLoading(false);
    getBitcoinPrice(currency || ProjectCurrencyTypes.USD).then(({ data }) => {
      console.log('data', data);
      if (data) {
        setBTCPrice(numberWithCommas(data.amount));
      }
    }).catch();
  }, [project, currency]);

  const handleSpotsPlusClick = () => {
    if (spotsAmount < availableSpotsAmount) {
      setSpotsAmount(spotsAmount + 1);
    }
  };

  const handleSpotsMinusClick = () => {
    if (spotsAmount > minimumOrderQuantity) {
      setSpotsAmount(spotsAmount - 1);
    }
  };

  const handleSpotsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    let sanitizedValue = currentValue.replace(/^0+/, '');

    if (Number(sanitizedValue) > availableSpotsAmount) {
      sanitizedValue = availableSpotsAmount.toString();
    }
    if (Number(sanitizedValue) < minimumOrderQuantity) {
      sanitizedValue = minimumOrderQuantity.toString();
    }
    setSpotsAmount(Number(sanitizedValue) || 0);
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = sanitizedValue;
  };
  const handleSpotsBlur = () => {
    if (spotsAmount < minimumOrderQuantity) {
      setSpotsAmount(minimumOrderQuantity);
    }
  };

  // const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const currentValue = event.currentTarget.value;
  //   const sanitizedValue = currentValue.replace(/^0+/, '');
  //
  //   setBTCPrice(formatNumber(sanitizedValue));
  // };
  const handleCurrencyChange = (value: string | string[] | React.ChangeEvent<HTMLSelectElement>) => {
    setCurrency(value as ProjectCurrencyTypes);
  };

  // const handlePriceBlur = () => {
  //   // Remove trailing decimal point if present
  //   if (btcPrice?.endsWith('.')) {
  //     setBTCPrice(formatNumber(btcPrice.slice(0, -1)));
  //   }
  // };

  const handlePeriodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    let sanitizedValue = currentValue.replace(/^0+/, '');

    if (Number(sanitizedValue) > maxHoldPeriod) {
      sanitizedValue = maxHoldPeriod.toString();
    }
    if (Number(sanitizedValue) < 0) {
      sanitizedValue = '0';
    }

    setHoldPeriod(Number(sanitizedValue));

    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = sanitizedValue;
  };

  const handlePeriodBlur = () => {
    if (holdPeriod > maxHoldPeriod) {
      setHoldPeriod(maxHoldPeriod);
    }
  };

  const infoRaws: InfoRawProps[] = [
    {
      label: 'Country',
      value: country,
    },
    {
      label: 'Energy Source',
      value: energySource,
    },
    {
      label: 'Electricity Price per kW/h',
      value: <CurrencyAmountBox
        projectCurrency={project?.currency}
        amount={electricityPrice || '0'}
        currency={CurrencyTypes.EUR}
        decimals={7}
      />,
    },
    {
      label: 'Monthly Electricity Cost *',
      value: !wattage ? '-' : (
        <CurrencyAmountBox
          projectCurrency={project?.currency}
          amount={electricityPrice * 720 * wattage * 0.001 * spotsAmount}
          currency={CurrencyTypes.EUR}
          decimals={7}
        />
      ),
    },
    {
      label: 'Model of the miner',
      value: minerModel,
    },
    {
      label: 'Order Quantity',
      value: spotsAmount,
    },
    {
      label: 'Price per miner',
      value: <CurrencyAmountBox
        projectCurrency={project?.currency}
        amount={pricePerMiner}
        currency={CurrencyTypes.EUR}
        decimals={5}
      />,
    },
  ];

  const handleInvestClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isAuth) {
      event.stopPropagation();
      updateViewModal({
        type: ViewModalActionTypes.QuotationDetailsModal,
        payload: {
          title,
          description,
          ref: modalRef,
          info: {
            ...info,
            infoRaws,
            projectCurrency: project?.currency,
            projectId: id,
            totalPrice: spotsAmount * pricePerMiner,
            pricePerMiner,
            spotsAmount,
            projectName: title,
          },
        },
      });
    } else {
      navigate(`${AuthPaths.SignIn}?projectId=${id}&miners=${spotsAmount}`);
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-col">
      {!isAuth && (
        <div className="relative w-full bg-transparent px-10 max-lg:px-5 flex items-center justify-between h-20 max-lg:h-[4.5rem] border-b border-medium">
          <div className="flex items-center gap-4">
            <NavLink to={Paths.Projects}>
              <PantheonIcon className="max-sm:w-32" />
            </NavLink>
          </div>
          <Button category={ButtonCategory.Filled} className="h-7 px-5 text-[10px] rounded-md">
            <NavLink to={AuthPaths.SignIn}>LOG IN</NavLink>
          </Button>
        </div>
      )}

      <div className={`flex flex-col gap-8 h-full text-white ${!isAuth ? 'px-10 max-lg:px-5 py-8' : ''}`}>
        <div className="flex gap-5 max-lg:flex-col relative">
          <div className="min-w-[320px]">
            <div className="flex flex-col gap-4 lg:sticky lg:top-8 lg:z-10">
              <h4 className="mb-8">Investment Calculator</h4>
              <div>
                <h5 className="mb-3 text-[13px]">Choose Project</h5>
                <Select
                  className="text-[13px]"
                  options={getProjectOptions(projects)}
                  placeholder="Select project"
                  value={project?.id || ''}
                  onChange={(value) => {
                    const currentProject = projects.find((item) => item.id === value);
                    if (currentProject) {
                      setProject(currentProject);
                      if (currentProject.minimumOrderQuantity > spotsAmount) {
                        setSpotsAmount(currentProject.minimumOrderQuantity);
                      }
                      if (currency !== currentProject.currency) {
                        setCurrency(currentProject.currency);
                      }
                    }
                  }}
                />
              </div>
              <div>
                <h5 className="mb-3 text-[13px]">Choose Miners</h5>
                <Select
                  className="text-[13px]"
                  options={getProjectMinersOptions(project)}
                  placeholder="Select Miner"
                  value={project?.minerModel || ''}
                />
              </div>
              <div>
                <h5 className="mb-3 text-[13px]">Choose Currency</h5>
                <Select
                  className="text-[13px]"
                  options={getProjectCurrencyOptions()}
                  onChange={(value) => {
                    console.log('select Value', value);
                    handleCurrencyChange(value);
                  }}
                  placeholder="Select Miner"
                  value={currency || ''}
                />
              </div>
              <div>
                <h5 className="mb-3 text-[13px]">Miners</h5>
                <div className="flex justify-between gap-4 items-center">
                  <div className="flex items-center gap-4">
                    <div className={`flex justify-center items-center h-8 w-8 rounded-full bg-lightBrown ${spotsAmount > minimumOrderQuantity ? 'opacity-100 cursor-pointer' : 'opacity-40'}`} onClick={handleSpotsMinusClick}><MinusIcon /></div>
                    <input
                      className="font-medium bg-transparent"
                      style={{ width: `${spotsAmount.toString().length * 10}px` }}
                      type="number"
                      min={minimumOrderQuantity}
                      onChange={handleSpotsChange}
                      onBlur={handleSpotsBlur}
                      value={spotsAmount}
                    />
                    <div className={`flex justify-center items-center h-8 w-8 rounded-full bg-lightBrown ${spotsAmount < availableSpotsAmount ? 'opacity-100 cursor-pointer' : 'opacity-40'}`} onClick={handleSpotsPlusClick}><PlusIcon /></div>
                  </div>
                  <div className="flex flex-col gap-1 items-end [&>span]:leading-[100%]">
                    {`${getCurrencySign(currency)} ${numberWithCommas(initialInvestment, '', 4)}`}
                    <span className="text-[12px]">
                      {`${getCurrencySign(
                        currency === ProjectCurrencyTypes.USD
                          ? ProjectCurrencyTypes.EUR
                          : ProjectCurrencyTypes.USD,
                      )} ${numberWithCommas(
                        spotsAmount * pricePerMiner * (currency === ProjectCurrencyTypes.EUR ? 1 : Number(rates?.rates.eUR)),
                        '',
                        4,
                      )}`}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="mb-3 text-[13px]">Hold Period</h5>
                <div className="relative">
                  <input
                    className="w-full rounded-lg px-4 pr-16 py-3 text-[13px] border focus:outline-none h-12 bg-transparent border-default focus:border-lightBrown focus:border-2"
                    placeholder="Select Miner"
                    type="number"
                    max={maxHoldPeriod}
                    min={0}
                    value={holdPeriod}
                    onChange={handlePeriodChange}
                    onBlur={handlePeriodBlur}
                  />
                  <span className="absolute flex items-center right-4 top-0 h-full text-[13px] text-white/70">month</span>
                </div>
              </div>
              <div className="py-2">
                <div className="flex items-center gap-3 mb-4">
                  <h5 className="text-[13px]">Scenario</h5>
                  <InfoIcon className="w-3 h-3 text-[#9FB2CC]" />
                </div>
                <div className="flex flex-wrap gap-3">
                  {scenarios.map((item) => (
                    <div
                      className={`p-4 w-fit font-medium text-[13px] rounded-lg cursor-pointer leading-none ${item === scenario ? 'text-[#01111D] bg-lightBrown border border-lightBrown' : 'text-mediumGray border border-white/30'}`}
                      onClick={() => { setScenario(item as ScenarioTypes); }}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="flex items-center gap-3 mb-3 relative">
                  <h5 className="text-[13px]">Current Bitcoin Price</h5>
                  <button
                    onClick={() => setBtcPricePopup(!btcPricePopup)}
                    onBlur={() => setBtcPricePopup(false)}
                    className="relative p-0 h-fit flex-1 text-left font-normal text-[#9FB2CC] hover:text-white"
                  >
                    <InfoIcon className="w-3 h-3" />
                    <div className={`absolute right-0 w-[200px] text-white bottom-5 bg-buttonFilled border border-[#2A455B] text-[12px] leading-[18px] rounded-lg shadow px-4 py-3 ${btcPricePopup ? 'block' : 'hidden'}`}>
                      The current price for the Bitcoin is being pulled from Coinbase API.
                    </div>
                  </button>
                </div>
                <div className="relative">
                  <Input
                    className="w-full rounded-lg px-4 pl-7 py-3 text-[13px] leading-none border focus:outline-none bg-white/4 border-default focus:border-lightBrown focus:border-2"
                    type="text"
                    value={btcPrice}
                    // onChange={handlePriceChange}
                    // onBlur={handlePriceBlur}
                    blocked
                    disabled
                  />
                  <span className="absolute flex items-center left-4 top-[1px] h-full text-[13px] text-[#99938C]">{getCurrencySign(currency) || '$'}</span>
                </div>
              </div>
              <Button
                className="mt-4"
                category={ButtonCategory.Filled}
                onClick={handleInvestClick}
                disabled={!project}
              >
                Purchase Now
              </Button>
            </div>
          </div>
          <div className="flex flex-col mt-20 flex-1 gap-4">
            <div className="grid grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1 gap-4 xl:min-w-[700px] md:min-w-[480px]">
              <InfoCard
                title="Cumulative Revenue"
                value={project ? totalRevenue : 0}
                prefix={currentCurrency}
                info="Cumulative Revenue in Bitcoin mining represents the total income generated from the mining operation over a 5-year period."
              />
              <InfoCard
                title="Cumulative Costs"
                value={project ? electricityUseCostMonthly * holdPeriod + initialInvestment : 0}
                prefix={project && currentCurrency}
                info="Cumulative Costs encompass all expenses incurred during the Bitcoin mining operation over a 5-year period. This includes costs related to mining hardware, CAPEX, electricity, maintenance, and other operational expenses."
              />
              <InfoCard
                title="Cumulative Net Cash Flow"
                value={project ? calculateCumulativeCashFlowMonthly(holdPeriod) : 0}
                prefix={currentCurrency}
                info="Cumulative Cash Flow in Bitcoin mining refers to the total net cash generated by the mining operation over a period of 5 years, after deducting all related expenses. This metric provides an overview of the net cash inflows and outflows, highlighting the overall profitability and financial health of the mining project."
              />
              <InfoCard
                title="IRR"
                value={project && irr !== null ? irr : 0}
                suffix="%"
                info="The IRR for Bitcoin mining is a financial metric used to evaluate the profitability of the mining operation over a 5-year period. It represents the annualized rate of return at which the net present value of all cash flows (both incoming from mined Bitcoins and outgoing for operational costs) equals zero. A higher IRR indicates a more profitable mining investment."
                errorMessage={irrError}
              />
              <InfoCard
                title="MOIC"
                value={project ? equityMultiple : 0}
                suffix={project ? 'x' : ''}
                info="Multiple on Invested Capital in Bitcoin mining is a ratio that measures the total return on the investment relative to the amount of equity invested over a 5-year period. It is calculated by dividing the total cash distributions (including profits from mined Bitcoins) by the total equity invested. An equity multiple greater than 1.0 indicates a positive return on the mining investment."
              />
              <InfoCard
                title="Total BTC Mined"
                value={project ? totalBtcProduction : 0}
                decimals={5}
                info="Total Bitcoin Mined refers to the number of Bitcoins successfully mined during the operation over a 5-year period."
              />
            </div>
            <div className="flex flex-col items-center max-h-96 gap-5 rounded-lg border border-white/30 px-5 py-7 w-full">
              <h5 className="text-[20px]">Bitcoin Mining Profitability Calculator</h5>
              <div className="flex w-full">
                <MixedChart
                  className="max-h-72"
                  labels={labels}
                  cashFlows={totalCashFlowsPerMonth}
                  profits={cashFlowsPerMonth}
                  revenues={revenuesPerMonth}
                  electricityCost={electricityUseCostPerMonth}
                  currency={currentCurrency}
                />
              </div>
            </div>
            <div className="grid grid-cols-3 max-2xl:grid-cols-2 max-sm:grid-cols-1 gap-4 w-full h-max">
              {(btcPriceIncrements[scenario] as number[]).map((increment, index) => {
                if (index + 1 > yearsCount) {
                  return null;
                }
                let currentPeriod = holdPeriod - 1;
                if (holdPeriod >= yearsCount * 12) {
                  currentPeriod = (index + 1) * 12 - 1;
                }

                const buyingBtcAmountYearly = getBuyingBtcAmount(
                  initialInvestment,
                  currentBtcPrice,
                  electricityUseCostYearly,
                  scenario,
                  currentPeriod,
                );

                const miningBtcAmountYearly = calculateTotalBtcProductionForPeriod(currentPeriod, spotsAmount, btcProductionPerMiner);
                const btcPriceInMonth = calculateCompoundPercentValue(currentBtcPrice, currentPeriod, { scenario });

                totalBuyingBtcAmount = buyingBtcAmountYearly;
                totalMiningBtcAmount = miningBtcAmountYearly;
                totalBuyingBtcAmountPrice = buyingBtcAmountYearly * btcPriceInMonth;
                totalMiningBtcAmountPrice = miningBtcAmountYearly * btcPriceInMonth;

                return (
                  <BTCInfoCard
                    title={`Year ${index + 1}`}
                    value={btcPriceInMonth}
                    increment={increment * 100}
                    buyingAmount={buyingBtcAmountYearly}
                    miningAmount={miningBtcAmountYearly}
                    currency={currentCurrency || '$'}
                  />
                );
              })}
              {yearsCount > 1 && (
              <div className="flex flex-col gap-2 rounded-lg border border-white/30 p-2 w-full">
                <div className="flex justify-between items-center gap-2 py-4 px-5">
                  <span className="font-bold text-[24px] leading-none">Buying DCA vs Mining BTC</span>
                  <button
                    onClick={() => setTotalMiningPopup(!totalMiningPopup)}
                    onBlur={() => setTotalMiningPopup(false)}
                    className="relative p-0 h-fit text-left font-normal text-[#9FB2CC] hover:text-white"
                  >
                    <InfoIcon />
                    <div className={`absolute right-0 w-[300px] max-2xl:w-[280px] max-xl:w-[250px] text-white bottom-8 bg-buttonFilled border border-[#2A455B] text-[12px] leading-[18px] rounded-lg shadow px-4 py-3 ${totalMiningPopup ? 'block' : 'hidden'}`}>
                      info
                    </div>
                  </button>
                </div>

                <div className="flex flex-col gap-0.5 rounded-lg overflow-hidden">
                  <div className="flex justify-between bg-filled p-4">
                    <span>Buying</span>
                    <span className="w-max">
                      ₿
                      {' '}
                      {numberWithCommas(totalBuyingBtcAmount, '', 5)}
                    </span>
                    <span className="w-max">
                      {getCurrencySign(currency)}
                      {' '}
                      {numberWithCommas(totalBuyingBtcAmountPrice, '', 2)}
                    </span>
                  </div>
                  <div className="flex justify-between bg-filled p-4">
                    <span>Mining</span>
                    <span className="w-max">
                      ₿
                      {' '}
                      {numberWithCommas(totalMiningBtcAmount, '', 5)}
                    </span>
                    <span className="w-max">
                      {getCurrencySign(currency)}
                      {' '}
                      {numberWithCommas(totalMiningBtcAmountPrice, '', 2)}
                    </span>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
        {!project && isLoading && (
          <Loader className="self-center flex-1 w-8 h-8" />
        )}
      </div>
    </div>
  );
};
